/* HOST ----------------------------------- */
:root {
	--panel-w: 420px;
	--panel-pad: var(--gap-l);
}

@media screen and (min-width: 1600px) {
	:root {
		--panel-w: 520px;
	}
}

#side-panel {
	width: var(--panel-w);
	position: fixed;
	top: calc(var(--gap-l) * 2);
	right: calc(var(--gap-l) * 2);
	bottom: calc(var(--gap-l) * 2);
	overflow-x: hidden;
	overflow-y: auto;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	/* justify-content: space-between; */
	gap: var(--gap-l);
	border: 24px solid white;
	border-image: url('/public/panel-frame.webp') 24;
	/* border-image-outset: 8px; */
	background-color: var(--clr-background);
	transition: 300ms;
	transition-timing-function: ease-in-out;
	z-index: 6;
	filter: blur(var(--sp-1));
}

#side-panel[data-state-visibility='open'] {
	transform: translateX(0px);
	filter: blur(0);
}

@media screen and (max-width: 1200px) {
	#side-panel {
		/*  */
		/* This property needs to be toggled on via prop/data attribute etc for the panel reveal mechanism */
		/*  */
		transform: translateX(calc(var(--panel-w) + var(--sp-12)));
	}
}

@media screen and (max-width: 720px) {
	:root {
		--panel-w: 100%;
	}
	#side-panel {
		top: 0;
		right: var(--gap-s);
		bottom: 0;
		left: 0;
	}
}

/* PANEL ACTIONS -------------------------- */
#panel-action-container {
	padding: var(--panel-pad);
	padding-bottom: 0;
	display: flex;
	flex-direction: column;
	gap: var(--gap-s);
}

#panel-action-container > .row {
	display: flex;
	gap: var(--gap-s);
}

#panel-action-container > .row:first-child > * {
	width: 50%;
	height: var(--sp-4);
	padding: var(--gap-min);
	flex-grow: 1;
	border-style: none;
	border: 1px dashed var(--clr-accent);
	border-radius: var(--sp-4);
	background-color: transparent;
	color: var(--clr-accent);
	font-family: var(--font-body);
	font-size: 0.675rem;
	cursor: pointer;
	transition: 120ms;
}

#panel-action-container > .row:first-child > *:hover {
	transform: scale(1.05);
}

/* Tab Container */
#tab-container {
	height: var(--sp-5);
	flex-grow: 1;
	display: flex;
	border: 1px solid var(--clr-accent);
	border-radius: var(--sp-5);
	overflow: hidden;
}

#tab-container > * {
	width: 50%;
	flex-grow: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: var(--font-body);
	font-size: 0.875rem;
	font-weight: 500;
	color: var(--clr-accent);
	cursor: pointer;
}

#tab-container > *:first-child {
	border-right: 1px solid var(--clr-accent);
}

#tab-container > *[data-state='active'] {
	background-color: var(--clr-accent);
	color: var(--clr-background);
}

/* HIDE BUTTON----------------------------- */
@media screen and (min-width: 1201px) {
	#hide-button {
		display: none;
	}
}

/* VIEW SECTIONS -------------------------- */
section {
	padding: var(--gap-l);
	padding-top: 0;
	flex-grow: 1;
	display: none;
	flex-direction: column;
	gap: var(--gap-s);
}

section[data-state='active'] {
	display: flex;
}

/* FOCUS TOKEN CONTAINER ------------------ */
#focus-token-container {
	padding: var(--gap-l);
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	gap: var(--gap-l);
}

/* IMAGE ---------------------------------- */
#focus-token-image-container {
	flex-grow: 1;
	display: flex;
	justify-content: center;
	flex-start: start;
	border-radius: var(--bdr-i);
	background-image: var(--bg-url);
	background-size: cover;
	background-position: center;
}

/* TITLE ---------------------------------- */
#focus-token-title-container {
	height: var(--sp-3);
	display: flex;
	justify-content: center;
	align-items: center;
}

#focus-token-title-container > span {
	color: var(--clr-secondary);
	font-family: var(--font-body);
	text-transform: var(--font-body);
	font-weight: 600;
	font-size: 0.875rem;
}

/* DETAILS -------------------------------- */
.details-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

#selected-token-details-container {
	flex-grow: 1;
}

.details-container > .item {
	padding: var(--gap-s) 0;
	/* height: var(--sp-5);
	min-height: fit-content; */
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px dashed var(--clr-primary-tr-light);
}
.details-container > .item:last-child {
	border-bottom: none;
}

.details-container > .item > span {
	font-family: var(--font-body);
	font-size: 0.75rem;
}

.details-container > .item > span:first-child {
	width: 66%;
}

.details-container > .item > span:last-child {
	width: 33%;
}

.details-container > .item > span:first-child {
	font-weight: 600;
	color: var(--clr-secondary);
}

.details-container > .item > span:last-child {
	color: var(--clr-accent);
	font-weight: 400;
}

/* BALANCE -------------------------------- */
#balance-container {
	height: var(--sp-5);
	display: flex;
	border: 1px dashed var(--clr-secondary);
	border-radius: var(--sp-1);
}

#balance-container > * {
	padding: var(--gap-l);
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: var(--font-body);
	font-size: 0.75rem;
}

#balance-container > .label {
	color: var(--clr-secondary);
	border-right: 1px dashed var(--clr-secondary);
	font-weight: 500;
}

#balance-container > .value {
	flex-grow: 1;
	font-weight: 600;
}

/* INFOBOX -------------------------------- */
.info-box {
	overflow: hidden;
	display: flex;
	flex-direction: column;
	border: 1px solid var(--clr-secondary);
	border-radius: var(--sp-1);
}

.info-box > .title-row {
	padding: var(--gap-s);
	padding-bottom: 0;
	display: flex;
	align-items: center;
}

.info-box > .title-row > h3 {
	flex-grow: 1;
}

#info-box-what-is-kagami-lang-switch {
	display: flex;
	border: 1px dashed var(--clr-secondary);
	border-radius: var(--gap-min);
}

#info-box-what-is-kagami-lang-switch > span {
	/* padding: var(--gap-min); */
	/* flex-grow: 1; */
	padding: var(--gap-min) var(--gap-s);
	color: var(--clr-primary-tr-heavy);
	cursor: pointer;
}

#info-box-what-is-kagami-lang-switch > span:first-child {
	border-right: 1px dashed var(--clr-secondary);
}

#info-box-what-is-kagami-lang-switch > span[data-state='active'] {
	font-weight: 600;
	color: var(--clr-secondary);
}

.info-box > * {
	font-family: var(--font-body);
	font-size: 0.75rem;
}

.info-box > span {
	padding: var(--gap-s);
	display: flex;
	flex-direction: column;
	gap: var(--gap-min);
}

.info-box > h3 {
	color: var(--clr-primary);
}

.info-box > button {
	height: var(--sp-4);
	border-style: none;
	border-top: 1px solid var(--clr-secondary);
	background-color: transparent;
	color: var(--clr-secondary);
	cursor: pointer;
}

.info-box > button > * {
	transition: 120ms;
}

.info-box > button:hover > * {
	transform: scale(1.125);
}

/* DELIN ---------------------------------- */
.delin {
	border-top: 16px solid white;
	border-image: url('/public/border-c.webp') 16;
}

/* KAGAMI NFT ----------------------------- */
#nft-container-kagami {
	position: relative;
	overflow: hidden;
	flex-grow: 1;
	border-radius: var(--sp-2);
	background-image: url('/public/kagami-thumb.webp');
	background-size: cover;
	background-position: center;
}

.action-help-kagami-nft {
	height: var(--sp-4);
	/* width: var(--sp-4); */
	padding: 0 var(--gap-min) 0 var(--gap-l);
	position: absolute;
	right: var(--gap-min);
	display: flex;
	justify-content: center;
	align-items: center;
	gap: var(--gap-min);
	border-radius: var(--gap-max);
	border-style: none;
	background-color: var(--clr-surface);
	cursor: pointer;
	z-index: 4;
}

#action-help-kagami-nft-en {
	top: var(--gap-min);
}

#action-help-kagami-nft-jp {
	top: calc((var(--gap-min) * 3) + var(--sp-4));
}

.action-help-kagami-nft > svg {
	fill: var(--clr-accent);
}

.action-help-kagami-nft > span {
	font-family: var(--font-body);
	font-size: 0.675rem;
	font-weight: 500;
}

.overlay-help-kagami-nft {
	width: 100%;
	height: 100%;
	padding: var(--gap-l);
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	bottom: 0;
	background-color: rgba(4, 173, 239, 0.5);
	backdrop-filter: blur(var(--sp-2));
	opacity: 0;
	transition: 120ms;
}

.overlay-help-kagami-nft > .about-container {
	width: 100%;
	height: 100%;
	padding: var(--gap-s);
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid var(--clr-surface);
	border-radius: var(--sp-1);
}

.overlay-help-kagami-nft > .about-container > * {
	color: var(--clr-surface);
	font-family: var(--font-body);
	font-size: 0.75rem;
	text-align: center;
}

.overlay-help-kagami-nft[data-state='active'] {
	opacity: 1;
}

#action-mint {
	height: var(--sp-5);
	border: 1px solid var(--clr-accent);
	border-radius: var(--sp-5);
	background-color: transparent;
	font-family: var(--font-body);
	color: var(--clr-accent);
	cursor: pointer;
	transition: 120ms;
}

#action-mint:hover {
	transform: scale(1.05);
}
