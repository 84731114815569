@font-face {
	font-family: 'Sandbox Melodrama';
	src: url('https://db.onlinewebfonts.com/t/901f6402e0caed049873e8f32a4e40ee.eot');
	src: url('https://db.onlinewebfonts.com/t/901f6402e0caed049873e8f32a4e40ee.eot?#iefix') format('embedded-opentype'),
		url('https://db.onlinewebfonts.com/t/901f6402e0caed049873e8f32a4e40ee.woff2') format('woff2'),
		url('https://db.onlinewebfonts.com/t/901f6402e0caed049873e8f32a4e40ee.woff') format('woff'),
		url('https://db.onlinewebfonts.com/t/901f6402e0caed049873e8f32a4e40ee.ttf') format('truetype'),
		url('https://db.onlinewebfonts.com/t/901f6402e0caed049873e8f32a4e40ee.svg#Sandbox Melodrama') format('svg');
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html,
body,
#root {
	min-width: 100%;
	max-width: 100%;
	height: fit-content;
	min-height: 100vh;
}

@media screen and (max-width: 960px) {
	html,
	body,
	#root {
		max-height: fit-content;
	}
}

.App {
	height: 100%;
	min-height: 100vh;
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
}

.App::before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	border: 16px solid white;
	border-image: url('/public/page-border-outer.webp') 28;
	z-index: -1;
}

@media screen and (max-width: 720px) {
	.App {
		padding: var(--gap-max);
	}
}

/* APP BODY ------------------------------- */
#app-body {
	flex-grow: 1;
	display: flex;
	justify-content: space-between;
}

/* BORDER CONTAINERS ---------------------- */
#border-container-top,
#border-container-bottom,
#border-container-left,
#border-container-right {
	padding: var(--sp-2);
	display: flex;
	justify-content: space-between;
	z-index: -2;
}

@media screen and (max-width: 720px) {
	#border-container-top,
	#border-container-bottom,
	#border-container-left,
	#border-container-right {
		display: none;
	}
}

#border-container-top,
#border-container-bottom {
	height: var(--sp-9);
	min-height: var(--sp-9);
}

#border-container-left,
#border-container-right {
	width: var(--sp-9);
	min-width: var(--sp-9);
	padding-top: 0;
	padding-bottom: 0;
	max-height: calc(100vh - (var(--sp-9) * 2));
	flex-direction: column;
}

#border-container-top {
	padding-bottom: 0;
}

#border-container-bottom {
	padding-top: 0;
}

#border-container-left {
	padding-right: 0;
}

#border-container-right {
	padding-left: 0;
}

/* Grid items */
.border-grid-item {
	/* TEMP */
	display: flex;
	justify-content: center;
	align-items: center;
}

#border-container-top > .border-grid-item,
#border-container-bottom > .border-grid-item {
	width: calc(var(--page-pad) * 5);
	background-image: url('/public/border-motif-a.webp');
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}
#border-container-top > .border-grid-item:nth-child(2n),
#border-container-bottom > .border-grid-item:nth-child(2n) {
	background-image: url('/public/border-motif-b.webp');
}

@media screen and (max-width: 960px) {
	#border-container-top > .border-grid-item:nth-child(n + 5),
	#border-container-bottom > .border-grid-item:nth-child(n + 5) {
		display: none;
	}
}

@media screen and (min-width: 961px) and (max-width: 1200px) {
	#border-container-top > .border-grid-item:nth-child(n + 7),
	#border-container-bottom > .border-grid-item:nth-child(n + 7) {
		display: none;
	}
}

@media screen and (min-width: 1201px) and (max-width: 1600px) {
	#border-container-top > .border-grid-item:nth-child(n + 9),
	#border-container-bottom > .border-grid-item:nth-child(n + 9) {
		display: none;
	}
}

#border-container-left > .border-grid-item,
#border-container-right > .border-grid-item {
	height: calc(var(--page-pad) * 5);
	background-image: url('/public/border-motif-b-y.webp');
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}

#border-container-left > .border-grid-item:nth-child(2n),
#border-container-right > .border-grid-item:nth-child(2n) {
	background-image: url('/public/border-motif-a-y.webp');
}
@media screen and (max-width: 960px) {
	#border-container-left,
	#border-container-right {
		width: var(--gap-s);
		min-width: var(--gap-s);
	}

	#border-container-left > .border-grid-item,
	#border-container-right > .border-grid-item {
		display: none;
	}
}

@media screen and (max-height: 960px) {
	#border-container-left > .border-grid-item:nth-child(n + 5),
	#border-container-right > .border-grid-item:nth-child(n + 5) {
		display: none;
	}
}

@media screen and (min-height: 961px) and (max-height: 1600px) {
	#border-container-left > .border-grid-item:nth-child(n + 8),
	#border-container-right > .border-grid-item:nth-child(n + 8) {
		display: none;
	}
}

@media screen and (min-height: 1601px) {
	#border-container-left > .border-grid-item:nth-child(n + 10),
	#border-container-right > .border-grid-item:nth-child(n + 10) {
		display: none;
	}
}

/* @media screen and (min-width: 961px) and (max-width: 1200px) {
	#border-container-left > .border-grid-item:nth-child(n + 5),
	#border-container-right > .border-grid-item:nth-child(n + 5) {
		display: none;
	}
}

@media screen and (min-width: 1201px) and (max-width: 1600px) {
	#border-container-left > .border-grid-item:nth-child(n + 6),
	#border-container-right > .border-grid-item:nth-child(n + 6) {
		display: none;
	} */

/* TYPOGRAPHY ----------------------------- */
p {
	font-family: var(--font-display);
	font-size: max(1.25rem, 1.375vw);
}

/* PRELAUNCH ------------------------------ */
#prelaunch {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: var(--gap-max);
}

#prelaunch > img {
	width: 80vw;
	max-width: 720px;
}

/* COUNTDOWN ------------------------------ */
#countdown-container {
	height: var(--sp-9);
	display: flex;
	border: 1px solid var(--clr-primary-tr-light);
	border-radius: var(--sp-9);
}

#countdown-container > * {
	width: 12.5vw;
	max-width: 560px;
	flex-grow: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: var(--font-body);
	font-size: max(1vw, 0.75rem);
}

@media screen and (max-width: 800px) {
	#countdown-container {
		width: 50vw;
	}
}

#countdown-container > *:not(:last-child) {
	border-right: 1px dashed var(--clr-primary-tr-light);
}
