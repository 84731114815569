/**
 * Do not edit directly
 * Generated on Mon, 08 Jan 2024 10:59:46 GMT
 */

:root {
  --font-case-button: Uppercase;
  --font-case-body: none;
  --font-case-display: none;
  --font-body: JetBrains Mono,monospace;
  --font-display: Sandbox Melodrama,Arial,sans-serif;
  --fw-12: 900;
  --fw-11: 850;
  --fw-10: 800;
  --fw-9: 720;
  --fw-8: 650;
  --fw-7: 575;
  --fw-6: 520;
  --fw-5: 500;
  --fw-4: 480;
  --fw-3: 440;
  --fw-2: 380;
  --fw-1: 300;
  --fs-small-01: 0.525rem;
  --fs-small-00: 0.4rem;
  --fs-small-12: 2.275rem;
  --fs-small-11: 2.15rem;
  --fs-small-10: 2.025rem;
  --fs-small-9: 1.9rem;
  --fs-small-8: 1.775rem;
  --fs-small-7: 1.525rem;
  --fs-small-6: 1.275em;
  --fs-small-5: 1.15rem;
  --fs-small-4: 1.025rem;
  --fs-small-3: 0.9rem;
  --fs-small-2: 0.775rem;
  --fs-small-1: 0.65rem;
  --fs-01: 0.625rem;
  --fs-00: 0.5rem;
  --fs-12: 3.625rem;
  --fs-11: 3.375rem;
  --fs-10: 3.125rem;
  --fs-9: 2.875rem;
  --fs-8: 2.625rem;
  --fs-7: 2.375rem;
  --fs-6: 2.125em;
  --fs-5: 1.875rem;
  --fs-4: 1.625rem;
  --fs-3: 1.375rem;
  --fs-2: 1.125rem;
  --fs-1: 0.875rem;
  --sp-large-01: 6px;
  --sp-large-12: 144px;
  --sp-large-11: 132px;
  --sp-large-10: 120px;
  --sp-large-9: 108px;
  --sp-large-8: 96px;
  --sp-large-7: 84px;
  --sp-large-6: 72px;
  --sp-large-5: 60px;
  --sp-large-4: 48px;
  --sp-large-3: 36px;
  --sp-large-2: 24px;
  --sp-large-1: 12px;
  --sp-small-01: 4px;
  --sp-small-12: 74px;
  --sp-small-11: 68px;
  --sp-small-10: 62px;
  --sp-small-9: 56px;
  --sp-small-8: 50px;
  --sp-small-7: 44px;
  --sp-small-6: 38px;
  --sp-small-5: 32px;
  --sp-small-4: 26px;
  --sp-small-3: 20px;
  --sp-small-2: 14px;
  --sp-small-1: 8px;
  --sp-01: 4px;
  --sp-00: 2px;
  --sp-12: 96px;
  --sp-11: 88px;
  --sp-10: 80px;
  --sp-9: 72px;
  --sp-8: 64px;
  --sp-7: 56px;
  --sp-6: 48px;
  --sp-5: 40px;
  --sp-4: 32px;
  --sp-3: 24px;
  --sp-2: 16px;
  --sp-1: 8px;
  --scale-transform-basis: 0.04;
  --scale-transform-factor: 2;
  --scale-time-basis: 60ms;
  --scale-time-factor: 3;
  --scale-type-basis: 0.5rem;
  --scale-type-factor: 1.250;
  --root-border-width: 1;
  --root-color-midground: 255, 255, 255;
  --root-color-background: 254, 254, 254;
  --root-color-accent: 239, 91, 161;
  --root-color-secondary: 3,174,239;
  --root-color-primary: 72,72,72;
  --clr-sentiment-negative-rgb: 255, 80, 133;
  --clr-sentiment-positive-rgb: 80, 248, 148;
  --clr-ink-secondary-rgb-inverted: 255,251,238;
  --clr-ink-secondary-rgb: 255,251,238;
  --shell-background: transparent;
  --nav-height: var(--sp-12);
  --section-border-clr: transparent;
  --section-pad: var(--gap-l);
  --page-border-clr: var(--clr-primary-tr-light);
  --page-pad: var(--sp-5);
  --gap-scale: 1;
  --gap-basis: 8px;
  --scale-transform-min: var(--scale-transform-basis);
  --scale-transform-4: calc(var(--scale-transform-basis) * (var(--scale-transform-factor) * 4));
  --scale-transform-3: calc(var(--scale-transform-basis) * (var(--scale-transform-factor) * 3));
  --scale-transform-2: calc(var(--scale-transform-basis) * (var(--scale-transform-factor) * 2));
  --scale-transform-1: calc(var(--scale-transform-basis) * var(--scale-transform-factor));
  --scale-time-min: var(--scale-time-basis);
  --scale-time-6: calc((var(--scale-time-basis) * 6) * var(--scale-time-factor));
  --scale-time-5: calc((var(--scale-time-basis) * 5) * var(--scale-time-factor));
  --scale-time-4: calc((var(--scale-time-basis) * 4) * var(--scale-time-factor));
  --scale-time-3: calc((var(--scale-time-basis) * var(--scale-time-factor)) * 3);
  --scale-time-2: calc((var(--scale-time-basis) * 2) * var(--scale-time-factor));
  --scale-time-1: calc(var(--scale-time-basis) * var(--scale-time-factor));
  --scale-type-min: var(--scale-type-basis);
  --scale-type-12: calc((var(--scale-type-basis) * 12) * var(--scale-type-factor));
  --scale-type-11: calc((var(--scale-type-basis) * 11) * var(--scale-type-factor));
  --scale-type-10: calc((var(--scale-type-basis) * 10) * var(--scale-type-factor));
  --scale-type-9: calc((var(--scale-type-basis) * 9) * var(--scale-type-factor));
  --scale-type-8: calc((var(--scale-type-basis) * 8) * var(--scale-type-factor));
  --scale-type-7: calc((var(--scale-type-basis) * 7) * var(--scale-type-factor));
  --scale-type-6: calc((var(--scale-type-basis) * 6) * var(--scale-type-factor));
  --scale-type-5: calc((var(--scale-type-basis) * 5) * var(--scale-type-factor));
  --scale-type-4: calc((var(--scale-type-basis) * 4) * var(--scale-type-factor));
  --scale-type-3: calc((var(--scale-type-basis) * 3) * var(--scale-type-factor));
  --scale-type-2: calc((var(--scale-type-basis) * 2) * var(--scale-type-factor));
  --scale-type-1: calc(var(--scale-type-basis) * var(--scale-type-factor));
  --root-color-ink: var(--root-color-primary);
  --root-color-surface: var(--root-color-background);
  --fx-glow-1: 0 0 var(--sp-2) var(--clr-primary-tr-light);
  --ev-blur-1: blur(var(--sp-1));
  --clr-sentiment-negative-tr-heavy: rgba(var(--clr-sentiment-negative-rgb), 0.5);
  --clr-sentiment-negative-tr-light-x: rgba(var(--clr-sentiment-negative-rgb), 0.025);
  --clr-sentiment-negative-tr-light: rgba(var(--clr-sentiment-negative-rgb), 0.05675);
  --clr-sentiment-negative-tr-invisible: rgba(var(--clr-sentiment-negative-rgb), 0);
  --clr-sentiment-negative: rgba(var(--clr-sentiment-negative-rgb), 1);
  --clr-sentiment-positive-tr-heavy: rgba(var(--clr-sentiment-positive-rgb), 0.75);
  --clr-sentiment-positive-tr-light-x: rgba(var(--clr-sentiment-positive-rgb), 0.025);
  --clr-sentiment-positive-tr-light: rgba(var(--clr-sentiment-positive-rgb), 0.25);
  --clr-sentiment-positive-tr-invisible: rgba(var(--clr-sentiment-positive-rgb), 0);
  --clr-sentiment-positive: rgba(var(--clr-sentiment-positive-rgb), 1);
  --clr-ink-secondary-tr-heavy-x: rgba(var(--clr-ink-secondary-rgb), 0.85);
  --clr-ink-secondary-tr-heavy: rgba(var(--clr-ink-secondary-rgb), 0.75);
  --clr-ink-secondary-tr-light: rgba(var(--clr-ink-secondary-rgb), 0.25);
  --clr-ink-secondary-tr-light-x: rgba(var(--clr-ink-secondary-rgb), 0.025);
  --clr-ink-secondary: rgba(var(--clr-ink-secondary-rgb), 1);
  --clr-ink-rgb-inverted: 65, 56, 77;
  --clr-midground-transparency: 0;
  --clr-midground-rgb-inverted: var(--clr-surface-rgb-inverted);
  --clr-midground-rgb: var(--root-color-midground);
  --clr-background-rgb-inverted: 254, 254, 248;
  --clr-background-rgb: var(--root-color-background);
  --clr-surface-heavy-transparency: 0.125;
  --clr-surface-heavy-rgb-inverted: 72,72,72;
  --clr-surface-heavy-rgb: 72,72,72;
  --clr-surface-light-transparency: 0.0125;
  --clr-surface-light-rgb-inverted: 72,72,72;
  --clr-surface-light-rgb: 72,72,72;
  --clr-surface-transparency: 1;
  --clr-surface-rgb-inverted: 72,72,72;
  --clr-secondary-tr-light-x: rgba(0.025);
  --clr-secondary-rgb-inverted: 65, 56, 77;
  --clr-secondary-rgb: var(--root-color-secondary);
  --clr-accent-heavy-rgb: 210, 174, 255;
  --clr-accent-light-rgb: 250, 248, 255;
  --clr-accent-rgb: var(--root-color-accent);
  --clr-primary-light-rgb-inverted: 65, 56, 77;
  --clr-primary-light-rgb: 185, 217, 193;
  --clr-primary-rgb-inverted: 65, 56, 77;
  --clr-primary-rgb: var(--root-color-primary);
  --bdr-scale: 1;
  --bdr-basis: 8px;
  --bdw: var(--root-border-width)px;
  --section-border: var(--bdw) solid var(--section-border-clr);
  --page-border: var(--bdw) solid var(--page-border-clr);
  --gap-s: var(--gap-basis);
  --animate-transform-scale-1: scale(calc(1 + var(--scale-transform-1)));
  --animate-duration-2: var(--scale-time-6);
  --animate-duration-1: var(--scale-time-4);
  --ix-clr-active-from-sentiment-negative-tr-invisible: var(--clr-sentiment-negative-tr-heavy);
  --ix-clr-active-from-sentiment-positive-tr-invisible: var(--clr-sentiment-positive-tr-heavy);
  --ix-clr-hover-from-sentiment-negative-tr-invisible: var(--clr-sentiment-negative-tr-light);
  --ix-clr-hover-from-sentiment-positive-tr-invisible: var(--clr-sentiment-positive-tr-light);
  --ix-transform-active: scale(calc(1 - var(--scale-transform-min)));
  --ix-transform-hover: scale(calc(1 + var(--scale-transform-1)));
  --ix-transition-active: var(--scale-time-min);
  --ix-transition-hover: var(--scale-time-1);
  --clr-ink-secondary-on-heavy-inverted: rgba(var(--clr-background-rgb), 1);
  --clr-ink-on-heavy: rgba(var(--clr-background-rgb), 1);
  --clr-ink-rgb: var(--root-color-ink);
  --clr-midground: rgba(var(--clr-midground-rgb), var(--clr-midground-transparency));
  --clr-background: rgba(var(--clr-background-rgb), 1);
  --clr-surface-heavy: rgba(var(--clr-surface-heavy-rgb), var(--clr-surface-heavy-transparency));
  --clr-surface-light: rgba(var(--clr-surface-light-rgb), var(--clr-surface-light-transparency));
  --clr-surface-rgb: var(--root-color-surface);
  --clr-secondary-tr-heavy-x: rgba(var(--clr-secondary-rgb), 0.85);
  --clr-secondary-tr-heavy: rgba(var(--clr-secondary-rgb), 0.75);
  --clr-secondary-tr-light: rgba(var(--clr-secondary-rgb), 0.25);
  --clr-secondary-tr-invisible: rgba(var(--clr-secondary-rgb), 0);
  --clr-secondary: rgba(var(--clr-secondary-rgb), 1);
  --clr-accent-heavy: rgba(var(--clr-accent-heavy-rgb), 1);
  --clr-accent-light: rgba(var(--clr-accent-light-rgb), 1);
  --clr-accent: rgba(var(--clr-accent-rgb), 1);
  --clr-primary-light: rgba(var(--clr-primary-light-rgb), 1);
  --clr-primary-tr-heavy-x: rgba(var(--clr-primary-rgb), 0.85);
  --clr-primary-tr-heavy: rgba(var(--clr-primary-rgb), 0.75);
  --clr-primary-tr-light: rgba(var(--clr-primary-rgb), 0.25);
  --clr-primary-tr-light-x: rgba(var(--clr-primary-rgb), 0.025);
  --clr-primary-tr-invisible: rgba(var(--clr-primary-rgb), 0);
  --clr-primary: rgba(var(--clr-primary-rgb), 1);
  --bdr-i: var(--bdr-basis);
  --gap-l: calc(var(--gap-s) + (var(--gap-basis) * var(--gap-scale)));
  --gap-min: calc(var(--gap-s) - ((var(--gap-basis) * var(--gap-scale)) / 2));
  --ix-clr-active-from-primary-tr-invisible: var(--clr-primary-tr-heavy);
  --ix-clr-active-from-primary: var(--clr-accent-light);
  --ix-clr-hover-from-primary-tr-invisible: var(--clr-primary-tr-light);
  --ix-clr-hover-from-primary: var(--clr-accent);
  --clr-ink-secondary-on-heavy: var(--clr-background);
  --clr-ink-tr-heavy-x: rgba(var(--clr-ink-rgb), 0.85);
  --clr-ink-tr-heavy: rgba(var(--clr-ink-rgb), 0.75);
  --clr-ink-tr-light: rgba(var(--clr-ink-rgb), 0.25);
  --clr-ink-tr-light-x: rgba(var(--clr-ink-rgb), 0.025);
  --clr-ink: rgba(var(--clr-ink-rgb), 1);
  --clr-surface: rgba(var(--clr-surface-rgb), var(--clr-surface-transparency));
  --bdr-o: calc(var(--bdr-i) + (var(--bdr-basis) * var(--bdr-scale)));
  --bdr-min: calc(var(--bdr-i) - ((var(--bdr-basis) * var(--bdr-scale)) / 2));
  --gap-max: calc(var(--gap-l) + (var(--gap-basis) * var(--gap-scale)));
  --clr-ink-accent: var(--clr-ink);
}
