/* HOST ----------------------------------- */
#auction-gallery-container {
	padding: var(--gap-s);
	flex-grow: 1;
	display: flex;
	gap: var(--gap-max);
}

@media screen and (max-width: 960px) {
	#auction-gallery-container {
		flex-direction: column-reverse;
	}
}

/* SECTION -------------------------------- */

#auction-gallery-container > * {
	max-width: 50%;
}

@media screen and (max-width: 960px) {
	#auction-gallery-container > * {
		max-width: 100%;
	}
}

/* AUX ------------------------------------ */
#nft-aux-container {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	gap: var(--gap-l);
}

#nft-aux-container img {
	width: 100%;
}

/* About */
#nft-about-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

.nameplate {
	/* height: 100%; */
}

/* FRUITS REF ----------------------------- */
#fruits-reference-container {
	position: relative;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	gap: var(--gap-s);

	overflow: hidden;
}

.fruits-reference-img {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	flex-grow: 1;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	border: 32px solid white;
	border-image: url('/public/border-d.webp') 32;
	background-image: var(--bg-img);
	background-size: 120%;
	background-position: center 16%;
	/* background-repeat: no-repeat; */
	opacity: 0;
	transition: 300ms;
}

@media screen and (max-width: 960px) {
	#fruits-reference-container {
		min-height: 400px;
	}
}

.fruits-reference-img[data-state='active'] {
	opacity: 1;
}

/* NFT GALLERY ---------------------------- */
#nft-gallery-container {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	flex-direction: column-reverse;
	gap: var(--gap-l);
}

/* Nav */
#nav {
	height: var(--sp-9);
	display: flex;
	gap: var(--gap-min);
}

#nav > * {
	flex-grow: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	transition: 120ms;
	cursor: pointer;
}

#nft-anchor-1 {
	background-image: url('/public/nft-button-1.webp');
}

#nft-anchor-1:hover {
	transform: scale(1.125) rotate(-5deg);
}

#nft-anchor-2 {
	background-image: url('/public/nft-button-2.webp');
}

#nft-anchor-2:hover {
	transform: scale(1.125) rotate(3deg);
}

#nft-anchor-3 {
	background-image: url('/public/nft-button-3.webp');
}

#nft-anchor-3:hover {
	transform: scale(1.125) rotate(-6deg);
}

#nft-anchor-4 {
	background-image: url('/public/nft-button-4.webp');
}

#nft-anchor-4:hover {
	transform: scale(1.125) rotate(4deg);
}

#nft-anchor-5 {
	background-image: url('/public/nft-button-5.webp');
}

#nft-anchor-5:hover {
	transform: scale(1.125) rotate(-3deg);
}

#nft-anchor-6 {
	background-image: url('/public/nft-button-6.webp');
}

#nft-anchor-6:hover {
	transform: scale(1.125) rotate(4deg);
}

/* NFT ------------------------------------ */
#nft-container {
	position: relative;
	flex-grow: 1;
	display: flex;
	border: 16px solid white;
	border-image: url('/public/border-b.webp') 12;
}

@media screen and (max-width: 960px) {
	#nft-container {
		min-height: calc(var(--sp-12) * 7);
	}
}

#nft-container > img {
	width: 44px;
	height: 44px;
}

#corner-star-a {
	position: absolute;
	top: -32px;
	left: -32px;
}

#corner-star-b {
	position: absolute;
	top: -32px;
	right: -32px;
}

#corner-star-c {
	position: absolute;
	bottom: -32px;
	right: -32px;
}

#corner-star-d {
	position: absolute;
	bottom: -32px;
	left: -32px;
}

#action-bid {
	height: var(--sp-5);
	position: absolute;
	left: var(--gap-max);
	right: var(--gap-max);
	bottom: var(--gap-max);
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid var(--clr-accent);
	border-radius: var(--sp-2);
	background-color: rgba(255, 255, 255, 0.6);
	backdrop-filter: blur(12px);
	display: none;
	cursor: pointer;
	transition: 120ms;
}

#action-bid:hover {
	transform: scale(1.025);
}

@media screen and (max-width: 1200px) {
	#action-bid {
		display: flex;
	}
}

#action-bid > span {
	font-family: var(--font-body);
	font-weight: 500;
	color: var(--clr-accent);
}

/* IFRAME --------------------------------- */
iframe {
	width: 100%;
	height: 100%;
	border-style: none;
}

iframe:focus {
	outline: none;
}
