/* BID DIALOG -------------- */
dialog {
	width: 45vw;
	max-width: calc(var(--sp-12) * 3);
	padding: var(--gap-l);
	display: flex;
	flex-direction: column;
	gap: var(--gap-s);
	border-top: var(--bdw) solid var(--clr-ink);
	/* For some insane reason you need to do this for dialog borders? lol... */
	border-right: none;
	border-bottom: none;
	border-left: none;
	border: 16px solid white;
	border-image: url('/public/border-c.webp') 16;
	border-image-outset: 8px;
	/*  */
	border-radius: 0 0 var(--bdr-o) var(--bdr-o);
	background-color: var(--clr-background);
	opacity: 0;
	scale: 0;
	margin: auto;
	transition: 0;
}

dialog[open] {
	opacity: 1;
	scale: 1.5;
	transition: 250ms;
}

/* BUTTON --------------------------------- */
#place-bid-button {
	height: var(--sp-5);
	border-style: none;
	border: 1px solid var(--clr-accent);
	border-radius: var(--sp-4);
	background-color: transparent;
	color: var(--clr-accent);
	font-family: var(--font-body);
	cursor: pointer;
	transition: 120ms;
}

#place-bid-button:hover {
	transform: scale(1.05);
}

/* CURRENT BID ---------------------------- */
#current-bid-container {
	padding: var(--gap-s);
	display: flex;
	justify-content: space-between;
	border: calc(var(--bdw) / 2) solid var(--clr-ink-tr-light);
	border-radius: var(--bdr-min);
	font-size: 0.675rem;
}

.bid-modal-container > * {
	font-family: var(--font-display);
}

/* ACTIONS -------------------------------- */
.action-row {
	display: flex;
	gap: var(--gap-s);
}

.action-row > * {
	flex-grow: 1;
	font-size: 0.75rem;
	height: var(--sp-4);
	border: 1px solid var(--clr-accent);
	border-radius: var(--sp-4);
	background-color: var(--clr-accent);
	color: var(--clr-surface);
	font-family: var(--font-body);
}

.action-row > *:first-child {
	background-color: transparent;
	color: var(--clr-accent);
}

#bid-input {
	height: var(--sp-4);
	padding: var(--gap-l);
	font-size: 1rem;
	border: 1px dashed var(--clr-secondary);
	border-radius: var(--sp-4);
	color: var(--clr-secondary);
	font-family: var(--font-body);
}

#bid-input:focus {
	border-color: var(--clr-accent);
	outline: none;
	color: var(--clr-accent);
}
