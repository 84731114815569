#main-container {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
}

#main-container > * {
	max-width: calc(100% - (var(--panel-w) - var(--section-pad) * 2));
}

@media screen and (max-width: 1200px) {
	#main-container > * {
		max-width: 100%;
	}
}
