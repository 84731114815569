.host {
	height: var(--nav-height);
	display: flex;
	gap: var(--gap-s);
}

@media screen and (max-width: 960px) {
	:root {
		--nav-height: fit-content;
	}
}

.host > * {
	height: 100%;
	display: flex;
	gap: var(--gap-s);
}

@media screen and (max-width: 960px) {
	.host {
		flex-direction: column;
		align-items: center;
	}
}

/* BRAND ---------------------------------- */
#brand-container {
	flex-grow: 1;
	height: fit-content;
	display: flex;
	justify-content: center;
	align-items: center;
}

#brand-container > img {
	max-width: 80vw;
}

/* NAV ------------------------------------ */
#links-container {
	display: flex;
}

#links-container > * {
	/* border: 1px solid var(--clr-primary-tr-light); */
	width: var(--sp-9);
	height: 100%;
	min-height: var(--sp-9);
	display: flex;
	justify-content: center;
	align-items: center;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	transition: 120ms;
}

#links-container > *:first-child {
	background-image: url('./remi-social-ig.webp');
}

#links-container > *:first-child:hover {
	transform: rotate(-5deg);
}

#links-container > *:nth-child(2) {
	background-image: url('./fruits-social-ig.webp');
}

#links-container > *:nth-child(2):hover {
	transform: rotate(3deg);
}

#links-container > *:nth-child(3) {
	background-image: url('./merch.webp');
}

#links-container > *:nth-child(3):hover {
	transform: rotate(5deg);
}

#links-container > *:nth-child(4) {
	background-image: url('./blog.webp');
}

#links-container > *:nth-child(4):hover {
	transform: rotate(-4deg);
}

#bgm-player {
	min-height: 72px;
}
